/*
    A simple jQuery modal (http://github.com/kylefox/jquery-modal)
    Version 0.8.0
*/

(function(factory) {
    // Making your jQuery plugin work better with npm tools
    // http://blog.npmjs.org/post/112712169830/making-your-jquery-plugin-work-better-with-npm
    if (typeof module === "object" && typeof module.exports === "object") {
        factory(require("jquery"), window, document);
    } else {
        factory(jQuery, window, document);
    }
}(function($, window, document, undefined) {

    var modals = [],
        getCurrent = function() {
            return modals.length ? modals[modals.length - 1] : null;
        },
        selectCurrent = function() {
            var i,
            selected = false;
            for (i = modals.length - 1; i >= 0; i--) {
                if (modals[i].$blocker) {
                    modals[i].$blocker.toggleClass('current', !selected).toggleClass('behind', selected);
                    selected = true;
                }
            }
        };

    $.modal = function(el, options) {
        var remove, target;
        this.$body = $('body');
        this.options = $.extend({}, $.modal.defaults, options);
        this.options.doFade = !isNaN(parseInt(this.options.fadeDuration, 10));
        this.$blocker = null;
        if (this.options.closeExisting) while ($.modal.isActive())
        $.modal.close(); // Close any open modals.
        modals.push(this);
        if (el.is('a')) {
            target = el.attr('href');
            //Select element by id from href
            if (/^#/.test(target)) {
                this.$elm = $(target);
                if (this.$elm.length !== 1) return null;
                this.$body.append(this.$elm);
                this.open();
                //AJAX
            } else {
                this.$elm = $('<div>');
                this.$body.append(this.$elm);
                remove = function(event, modal) {
                    modal.elm.remove();
                };
                this.showSpinner();
                el.trigger($.modal.AJAX_SEND);
                $.get(target).done(function(html) {
                    if (!$.modal.isActive()) return;
                    el.trigger($.modal.AJAX_SUCCESS);
                    var current = getCurrent();
                    current.$elm.empty().append(html).on($.modal.CLOSE, remove);
                    current.hideSpinner();
                    current.open();
                    el.trigger($.modal.AJAX_COMPLETE);
                }).fail(function() {
                    el.trigger($.modal.AJAX_FAIL);
                    var current = getCurrent();
                    current.hideSpinner();
                    modals.pop(); // remove expected modal from the list
                    el.trigger($.modal.AJAX_COMPLETE);
                });
            }
        } else {
            this.$elm = el;
            this.$body.append(this.$elm);
            this.open();
        }
    };

    $.modal.prototype = {
        constructor: $.modal,

        open: function() {
            var m = this;
            this.block();
            if (this.options.doFade) {
                setTimeout(function() {
                    m.show();
                }, this.options.fadeDuration * this.options.fadeDelay);
            } else {
                this.show();
            }
            $(document).off('keydown.modal').on('keydown.modal', function(event) {
                var current = getCurrent();
                if (event.which == 27 && current.options.escapeClose) current.close();
            });
            if (this.options.clickClose) this.$blocker.click(function(e) {
                if (e.target == this) $.modal.close();
            });
        },

        close: function() {
            modals.pop();
            this.unblock();
            this.hide();
            if (!$.modal.isActive()) $(document).off('keydown.modal');
        },

        block: function() {
            this.$elm.trigger($.modal.BEFORE_BLOCK, [this._ctx()]);
            this.$body.css('overflow', 'hidden');
            this.$blocker = $('<div class="blocker  current  u-posFullScreen"></div>').appendTo(this.$body);
            selectCurrent();
            if (this.options.doFade) {
                this.$blocker.css('opacity', 0).animate({
                    opacity: 1
                }, this.options.fadeDuration);
            }
            this.$elm.trigger($.modal.BLOCK, [this._ctx()]);
        },

        unblock: function(now) {
            if (!now && this.options.doFade) this.$blocker.fadeOut(this.options.fadeDuration, this.unblock.bind(this, true));
            else {
                this.$blocker.children().appendTo(this.$body);
                this.$blocker.remove();
                this.$blocker = null;
                selectCurrent();
                if (!$.modal.isActive()) this.$body.css('overflow', '');
            }
        },

        show: function() {
            this.$elm.trigger($.modal.BEFORE_OPEN, [this._ctx()]);
            if (this.options.showClose) {
                this.closeButton = $('<a href="#modal-close" rel="modal:close" class="bpModal-close ' + this.options.closeClass + '">' + this.options.closeText + '</a>');
                this.$elm.append(this.closeButton);
            }
            this.$elm.addClass(this.options.modalClass).appendTo(this.$blocker);
            if (this.options.doFade) {
                this.$elm.css('opacity', 0).show().animate({
                    opacity: 1
                }, this.options.fadeDuration);
            } else {
                this.$elm.show();
            }
            this.$elm.trigger($.modal.OPEN, [this._ctx()]);
        },

        hide: function() {
            this.$elm.trigger($.modal.BEFORE_CLOSE, [this._ctx()]);
            if (this.closeButton) this.closeButton.remove();
            var _this = this;
            if (this.options.doFade) {
                this.$elm.fadeOut(this.options.fadeDuration, function() {
                    _this.$elm.trigger($.modal.AFTER_CLOSE, [_this._ctx()]);
                });
            } else {
                this.$elm.hide(0, function() {
                    _this.$elm.trigger($.modal.AFTER_CLOSE, [_this._ctx()]);
                });
            }
            this.$elm.trigger($.modal.CLOSE, [this._ctx()]);
        },

        showSpinner: function() {
            if (!this.options.showSpinner) return;
            this.spinner = this.spinner || $('<span class="' + this.options.modalClass + '-spinner  loader  loader--fill  is-pulsing"></span>')
                .append(this.options.spinnerHtml);
            this.$body.append(this.spinner);
            this.spinner.show();
        },

        hideSpinner: function() {
            if (this.spinner) this.spinner.remove();
        },

        //Return context for custom events
        _ctx: function() {
            return {
                elm: this.$elm,
                $blocker: this.$blocker,
                options: this.options
            };
        }
    };

    $.modal.close = function(event) {
        if (!$.modal.isActive()) return;
        if (event) event.preventDefault();
        var current = getCurrent();
        current.close();
        return current.$elm;
    };

    // Returns if there currently is an active modal
    $.modal.isActive = function() {
        return modals.length > 0;
    }

    $.modal.getCurrent = getCurrent;

    $.modal.defaults = {
        closeExisting: true,
        escapeClose: true,
        clickClose: true,
        closeText: '',
        closeClass: '',
        modalClass: "bpModal",
        spinnerHtml: null,
        showSpinner: true,
        showClose: true,
        fadeDuration: 200, // Number of milliseconds the fade animation takes.
        fadeDelay: .8 // Point during the overlay's fade-in that the modal begins to fade in (.5 = 50%, 1.5 = 150%, etc.)
    };

    // Event constants
    $.modal.BEFORE_BLOCK = 'modal:before-block';
    $.modal.BLOCK = 'modal:block';
    $.modal.BEFORE_OPEN = 'modal:before-open';
    $.modal.OPEN = 'modal:open';
    $.modal.BEFORE_CLOSE = 'modal:before-close';
    $.modal.CLOSE = 'modal:close';
    $.modal.AFTER_CLOSE = 'modal:after-close';
    $.modal.AJAX_SEND = 'modal:ajax:send';
    $.modal.AJAX_SUCCESS = 'modal:ajax:success';
    $.modal.AJAX_FAIL = 'modal:ajax:fail';
    $.modal.AJAX_COMPLETE = 'modal:ajax:complete';

    $.fn.modal = function(options) {
        if (this.length === 1) {
            new $.modal(this, options);
        }
        return this;
    };

    // Automatically bind links with rel="modal:close" to, well, close the modal.
    $(document).on('click.modal', 'a[rel~="modal:close"]', $.modal.close);
    $(document).on('click.modal', 'a[rel~="modal:open"]', function(event) {
        event.preventDefault();
        $(this).modal();
    });
}));

jQuery.extend( jQuery.easing,
  {
    def: 'easeOutQuad',
    swing: function (x, t, b, c, d) {
      //alert(jQuery.easing.default);
      return jQuery.easing[jQuery.easing.def](x, t, b, c, d);
    },
    easeInQuad: function (x, t, b, c, d) {
      return c*(t/=d)*t + b;
    },
    easeOutQuad: function (x, t, b, c, d) {
      return -c *(t/=d)*(t-2) + b;
    },
    easeInOutQuad: function (x, t, b, c, d) {
      if ((t/=d/2) < 1) return c/2*t*t + b;
      return -c/2 * ((--t)*(t-2) - 1) + b;
    },
    easeInCubic: function (x, t, b, c, d) {
      return c*(t/=d)*t*t + b;
    },
    easeOutCubic: function (x, t, b, c, d) {
      return c*((t=t/d-1)*t*t + 1) + b;
    },
    easeInOutCubic: function (x, t, b, c, d) {
      if ((t/=d/2) < 1) return c/2*t*t*t + b;
      return c/2*((t-=2)*t*t + 2) + b;
    },
    easeInQuart: function (x, t, b, c, d) {
      return c*(t/=d)*t*t*t + b;
    },
    easeOutQuart: function (x, t, b, c, d) {
      return -c * ((t=t/d-1)*t*t*t - 1) + b;
    },
    easeInOutQuart: function (x, t, b, c, d) {
      if ((t/=d/2) < 1) return c/2*t*t*t*t + b;
      return -c/2 * ((t-=2)*t*t*t - 2) + b;
    },
    easeInQuint: function (x, t, b, c, d) {
      return c*(t/=d)*t*t*t*t + b;
    },
    easeOutQuint: function (x, t, b, c, d) {
      return c*((t=t/d-1)*t*t*t*t + 1) + b;
    },
    easeInOutQuint: function (x, t, b, c, d) {
      if ((t/=d/2) < 1) return c/2*t*t*t*t*t + b;
      return c/2*((t-=2)*t*t*t*t + 2) + b;
    },
    easeInSine: function (x, t, b, c, d) {
      return -c * Math.cos(t/d * (Math.PI/2)) + c + b;
    },
    easeOutSine: function (x, t, b, c, d) {
      return c * Math.sin(t/d * (Math.PI/2)) + b;
    },
    easeInOutSine: function (x, t, b, c, d) {
      return -c/2 * (Math.cos(Math.PI*t/d) - 1) + b;
    },
    easeInExpo: function (x, t, b, c, d) {
      return (t==0) ? b : c * Math.pow(2, 10 * (t/d - 1)) + b;
    },
    easeOutExpo: function (x, t, b, c, d) {
      return (t==d) ? b+c : c * (-Math.pow(2, -10 * t/d) + 1) + b;
    },
    easeInOutExpo: function (x, t, b, c, d) {
      if (t==0) return b;
      if (t==d) return b+c;
      if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
      return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
    },
    easeInCirc: function (x, t, b, c, d) {
      return -c * (Math.sqrt(1 - (t/=d)*t) - 1) + b;
    },
    easeOutCirc: function (x, t, b, c, d) {
      return c * Math.sqrt(1 - (t=t/d-1)*t) + b;
    },
    easeInOutCirc: function (x, t, b, c, d) {
      if ((t/=d/2) < 1) return -c/2 * (Math.sqrt(1 - t*t) - 1) + b;
      return c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b;
    },
    easeInElastic: function (x, t, b, c, d) {
      var s=1.70158;var p=0;var a=c;
      if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
      if (a < Math.abs(c)) { a=c; var s=p/4; }
      else var s = p/(2*Math.PI) * Math.asin (c/a);
      return -(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
    },
    easeOutElastic: function (x, t, b, c, d) {
      var s=1.70158;var p=0;var a=c;
      if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
      if (a < Math.abs(c)) { a=c; var s=p/4; }
      else var s = p/(2*Math.PI) * Math.asin (c/a);
      return a*Math.pow(2,-10*t) * Math.sin( (t*d-s)*(2*Math.PI)/p ) + c + b;
    },
    easeInOutElastic: function (x, t, b, c, d) {
      var s=1.70158;var p=0;var a=c;
      if (t==0) return b;  if ((t/=d/2)==2) return b+c;  if (!p) p=d*(.3*1.5);
      if (a < Math.abs(c)) { a=c; var s=p/4; }
      else var s = p/(2*Math.PI) * Math.asin (c/a);
      if (t < 1) return -.5*(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
      return a*Math.pow(2,-10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )*.5 + c + b;
    },
    easeInBack: function (x, t, b, c, d, s) {
      if (s == undefined) s = 1.70158;
      return c*(t/=d)*t*((s+1)*t - s) + b;
    },
    easeOutBack: function (x, t, b, c, d, s) {
      if (s == undefined) s = 1.70158;
      return c*((t=t/d-1)*t*((s+1)*t + s) + 1) + b;
    },
    easeInOutBack: function (x, t, b, c, d, s) {
      if (s == undefined) s = 1.70158;
      if ((t/=d/2) < 1) return c/2*(t*t*(((s*=(1.525))+1)*t - s)) + b;
      return c/2*((t-=2)*t*(((s*=(1.525))+1)*t + s) + 2) + b;
    },
    easeInBounce: function (x, t, b, c, d) {
      return c - jQuery.easing.easeOutBounce (x, d-t, 0, c, d) + b;
    },
    easeOutBounce: function (x, t, b, c, d) {
      if ((t/=d) < (1/2.75)) {
        return c*(7.5625*t*t) + b;
      } else if (t < (2/2.75)) {
        return c*(7.5625*(t-=(1.5/2.75))*t + .75) + b;
      } else if (t < (2.5/2.75)) {
        return c*(7.5625*(t-=(2.25/2.75))*t + .9375) + b;
      } else {
        return c*(7.5625*(t-=(2.625/2.75))*t + .984375) + b;
      }
    },
    easeInOutBounce: function (x, t, b, c, d) {
      if (t < d/2) return jQuery.easing.easeInBounce (x, t*2, 0, c, d) * .5 + b;
      return jQuery.easing.easeOutBounce (x, t*2-d, 0, c, d) * .5 + c*.5 + b;
    }
  }
);


/*
 * UI JS
 * =========================================================================== */

const AWLAW = AWLAW || {};
AWLAW.global = AWLAW.global || {};

AWLAW.global.smoothScrollToElement = (function ($, window, document) {
  "use strict";

  let config = {
    body: $('html, body'),
    clickElement: $(".js-scrollToElement")
  };

  function initScroll () {
    config.clickElement < 0 || config.clickElement.click(function (e) {
      let speed = $(this).data("speed");
      let easing = $(this).data("easing");
      if (location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") && location.hostname == this.hostname) {
        var t = $(this.hash);
        if (t = t.length ? t : $("[name=" + this.hash.slice(1) + "]"), t.length) return config.body.animate({
          scrollTop: t.offset().top
        }, speed, easing), !1
      }
    });
  }

  return {
    init: initScroll
  };
})(jQuery, window, document);




AWLAW.global.modal = (function ($, window, document) {
  "use strict";

  // const $body = $("body");
  let config = {
    closeText: '',
    fadeDuration: 200,
    fadeDelay: .8
  };

  function _setupModals (config) {
    $('[data-modal]').on('click', function(event) {
      // $(this).modal(config);
      event.preventDefault();

      if ($(this).data('modal') !== '') {
        $($(this).data('modal')).modal(config);
      } else {
        $(this).modal(config);
      }

      // return false;
    });

  }

  function initModals (newConfig) {
    if (typeof newConfig === "object") {
      config = newConfig;
      // console.log(config.option);
    }
    _setupModals(config);
  }

  return {
    init: initModals
  };
})(jQuery, window, document);


AWLAW.global.general = (function ($, window, document) {
  "use strict";

  let $navToggle = $('.js-navToggle');
  let $body = $('body');


  function init () {
    $navToggle.on('click', function(e){
      e.preventDefault;
      let text = $navToggle.text();
      $(this).text(text == "Menu +" ? "Menu -" : "Menu +");
      $body.toggleClass('navOpen');
    });

  }

  return {
    init: init
  };
})(jQuery, window, document);


$(function () {
  AWLAW.global.smoothScrollToElement.init();
  AWLAW.global.modal.init();
  AWLAW.global.general.init();
});
